import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from '../../components/Layout';
import { AboutSidebarLinks } from '../../helpers/constants';
import EEWAuthorshipImage from "../../images/EEW-Authorship.png";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Layout pageTitle="People" sidebarLinks={AboutSidebarLinks} activeHeaderLink="About" mdxType="Layout">
      <h1>{`Core Contributors`}</h1>
      <p>{`Rather than presenting a hierarchical representation of contributions, below we diagram our collective authorship in accordance with `}<a href="https://docs.google.com/document/d/1SZeIVuJ4kjosq9GXTx8AauEF4RJYOftYyuPsj68kVck/edit#heading=h.k0ozbm3wj762">{`EDGI’s authorship protocol`}</a>{`: `}</p>
      <img src={EEWAuthorshipImage} className='full-width-image' alt="Diagram listing the names of core contributors on the EEW project, arranged by fields of care (data care, external care, event care, internal care, and research care" />
      <h1>{`Collaborators`}</h1>
      <ul>
        <li parentName="ul">
          <a href="https://www.stonybrook.edu/commcms/gss/" target='_blank'>Geospatial Center at Stony Brook University</a>
        </li>
        <li parentName="ul">
          <a href="http://apps.tlt.stonybrook.edu/" target='_blank'>Teaching Learning Lab at Stony Brook University</a>
        </li>
        <li parentName="ul">
          <a href="http://www.facebook.com/SunriseBoston/" target='_blank'>Sunrise Movement – Boston Hub</a>
        </li>
        <li parentName="ul">
          <a href="https://www.ucsusa.org//" target='_blank'>Union of Concerned Scientists</a>
        </li>
      </ul>
    </Layout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      